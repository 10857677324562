import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import NewsletterSignup from "../components/newsletterSignup";

class Contact extends React.Component {
  render() {
    const BigLinks = {
      fontSize: "45px",
      color: "#303F9F"
    };

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="File a Claim | Lewis Mohr Insurance Agency"
          meta={[
            {
              name: "description",
              content:
                "Please click below to go to your specific claims page."
            }
          ]}
        />
        <div>
          <Img
            alt="Abstact lighting"
            fluid={this.props.data.mainBg.childImageSharp.fluid}
            className="posts-bg"
          />
        </div>
        <div className="site-body page-content">
          <h1>File a Claim</h1>
          <p>
          Please click below to go to your specific claims page.
          </p>
          <div className="grid u-textCenter">            
            <div className="grid-cell u-full u-med-1of2">
              <Link style={BigLinks} to={"/file-a-claim/business/"}>Business</Link>
            </div>
            <div className="grid-cell u-full u-med-1of2">
              <Link style={BigLinks} to={"/file-a-claim/personal/"}>Personal</Link>
            </div>
          </div>
        </div>
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default Contact;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "abstract-bg-1.jpg" }) {
      ...mainBg
    }
  }
`;
